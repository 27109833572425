<div class="help-wrap">
  <!--Sticky Help-->
  <div *ngIf="initialFlags.contextualHelpEnabled" class="btn-group pull-left helpflyoutmenu contextual-help"
    >
    <button id="help-open" type="button" class="icon-container withBorder" aria-label="Help"
       (click)="toggleHelp(!isHelpOpen);logHelpIconEvent(isHelpOpen)"  [attr.aria-expanded]="isHelpOpen">
      <i class="icon icon-help"></i>
    </button>
    <div class="help-overlay"></div>
    <div class="fxp-help help-pinned" aria-labelledby="help-open" *ngIf="isHelpOpen">
      <div class="help-content">
        <div class="help-heading" *ngIf="initialFlags.contextualHelpEnabled">
          <button id="help-back-button" type="button" class="icon-container" title="Back" aria-label="Back"
            [disabled]="navigationCollection.length <= 1 || currentNavigationIndex === 0"
            (click)="navigateToPreviousState()" (keydown)="setFocusToNextElement($event)">
            <i class="icon icon-prev"></i>
          </button>
          <button id="help-forward-button" type="button" class="icon-container" title="Forward" aria-label="Forward"
            [disabled]="navigationCollection.length === currentNavigationIndex + 1" (click)="navigateToNextState()">
            <i class="icon icon-next"></i>
          </button>
          <button id="help-home-button" type="button" class="icon-container" title="Home" aria-label="Home"
            [disabled]="navigationCollection[currentNavigationIndex] === 'home'" (click)="navigateToHome()">
            <i class="icon icon-home"></i>
          </button>
          <button role="link" id="open-help-central" type="button" title="Open help central"
            aria-label="Open help central" class="icon-container" (click)="navigateToHelpCentral()">
            <i class="icon icon-newtab"></i>
          </button>
          <button id="helpFlyoutPin" aria-label="pin" class="icon-container pull-right" (click)="onPinHelpFlyoutClick()"
            [title]="!isHelpFlyoutPinned ? 'Click to Pin' : 'Click to Unpin'">
            <i class="icon"
              [ngClass]="{'icon-pinLegacy': !isHelpFlyoutPinned, 'icon-unpinLegacy': isHelpFlyoutPinned}"></i>
          </button>
          <div class="help-row" *ngIf="isHelpOpen">
            <section>
              <div class="search-control contextual-help">
                <input type="text" id="HelpSearch_FormControl" class="input__search"
                  placeholder="Search all Help in ESXP" [(ngModel)]="searchValue"
                  (keydown)="onSearchKeyDown($event, searchValue)" (selectItem)="onSearchSuggestionSelect($event)"
                  role="combobox" />
                <button class="fxp-btn fxp-btn--secondary icon-container clear-btn contextual-help"
                  *ngIf="searchValue.length" (click)="clearSearch()" aria-label="Clear search box">
                  <i class="icon icon-close"></i>
                </button>
                <button class="fxp-btn fxp-btn--secondary icon-container pull-right"
                  (click)="searchHelpArticles(searchValue)" aria-label="Search">
                  <i class="icon icon-search"></i>
                </button>

                <ng-template #searchResultTemplate let-match="match">
                  <div [id]="match.model" class="result-item">
                    <span attr.aria-label="Search Result {{$index}} is {{match.model}}">{{match.model}}</span>
                  </div>
                </ng-template>
              </div>
            </section>
          </div>
        </div>
        <div class="helpHeading" *ngIf="!initialFlags.contextualHelpEnabled">Help</div>
        <section *ngIf="contextualHelp != null && contextualHelp.length > 0 && navigationCollection[currentNavigationIndex] === 'home'">
          <!-- Include help/help-links.html content here -->
          <div class="help contextual-help" *ngFor="let sectionKey of Object.keys(contextualHelp)">
            <ng-container *ngIf="initialFlags.contextualHelpEnabled && contextualHelp[sectionKey].length">
              <h3 class="font-base-alt help-row help-header" [innerHTML]="sectionKey"></h3>
              <div class="help-row font-caption"
                *ngFor="let item of contextualHelp[sectionKey] | slice: 0:(isContextualFilterRequired ? defaultHelpArticleLimit : contextualHelp[sectionKey].length)">
                <div class="help-title">
                  <a href (click)="navigateToArticle(item.id); logFxpHelpEvent(item, sectionKey, 'FlyoutView')"
                    [title]="item.title" [attr.aria-label]="item.title" [attr.fxp-set-focus]="item.pullFocus"
                    *ngIf="sectionKey === helpArticleTitles.HELP_RELATED_TO_THIS_PAGE" [innerHTML]="item.title"></a>
                  <a href (click)="logFxpHelpEvent(item, item.title, 'FlyoutView')" [title]="item.title"
                    [attr.aria-label]="item.title" [attr.fxp-help-events]="item"
                    *ngIf="sectionKey !== helpArticleTitles.HELP_RELATED_TO_THIS_PAGE" [innerHTML]="item.title"></a>
                  <a class="help-icon-container article-edit" [title]="'Edit'"
                    attr.aria-label="'Edit article ' + item.title" [href]="helpCentralUrl + '&ArticleTitle=' + item.title"
                    target="_blank">
                    <i class="icon icon-edit-row"></i>
                  </a>
                </div>
                <div *ngIf="item.description" class="help-description" [innerHTML]="item.description"></div>
              </div>
              <div class="help-row font-caption"
                *ngIf="isContextualFilterRequired && sectionKey === helpArticleTitles.HELP_RELATED_TO_THIS_PAGE">
                <div class="help-title">
                  <a href (click)="showMoreContextualHelpLinks()" aria-label="Show all related articles">... Show all
                    related articles</a>
                </div>
              </div>
            </ng-container>
          </div>

        </section>
        <section *ngIf="templateCheck('article')">
          <!-- Include help/help-article.html content here -->
          <div class="help contextual-help" *ngIf="article.title">
            <div class="font-base-alt help-row">
              <span id="articleTitle">{{ article.title }}</span>
              <a class="help-icon-container article-edit" title="Edit" attr.aria-label="'Edit article ' + article.title"
                fxp-set-focus="true" [href]="helpCentralUrl + '&ArticleTitle=' + article.title" target="_blank">
                <i class="icon icon-edit-row"></i>
              </a>
            </div>
            <div class="help-row article-content" [innerHTML]="article.articleContent" [fxpHelpArticle]="article.title">

            </div>
            <div class="help-row font-caption">
              <div class="help-title opacity-6">Was this article helpful?</div>
              <div class="help-description">
                <button class="fxp-btn fxp-btn--secondary font-caption-alt"
                  attr.aria-label="'Was this article helpful? Yes'" (click)="saveHelpFeedback(article.id, 'true')">
                  Yes
                </button>
                <button class="fxp-btn fxp-btn--secondary font-caption-alt" title="No"
                  attr.aria-label="'Was this article helpful? No'" (click)="saveHelpFeedback(article.id, 'false')">
                  No
                </button>
              </div>
            </div>
            <div class="help-row font-caption" *ngIf="hasScrollbar">
              <a href fxp-go-to-top parent="fxp-help" id="help-gotop-button" role="button"
                (keydown)="setFocusToNextElement($event)">Go to top</a>
            </div>
          </div>


        </section>
        <section *ngIf="templateCheck('search')">
          <!-- Include help/search-links.html content here -->
          <div class="help contextual-help">
            <div class="font-base-alt help-row">Articles</div>
            <div
              *ngFor="let item of searchedHelp | slice:0:(isSearchFilterRequired ? defaultHelpArticleLimit : searchedHelp.length)"
              class="help-row font-caption">
              <div class="help-title">
                <a href
                  (click)="navigateToArticle(item.id); logFxpHelpEvent(item, 'Help related to this page', 'FlyoutView')"
                  title="{{item.title}}" attr.aria-label="{{item.title}}" [innerHTML]="sanitizeHtml(item.title)"></a>
                <a class="help-icon-container article-edit" title="Edit" attr.aria-label="Edit article {{item.title}}"
                  [href]="helpCentralUrl + '&ArticleTitle=' + item.title" target="_blank">
                  <i class="icon icon-edit-row"></i>
                </a>
              </div>
              <div *ngIf="item.description" class="help-description" [innerHTML]="sanitizeHtml(item.description)"></div>
            </div>
            <div class="help-row font-caption">
              <div class="help-title">
                <a href (click)="showMoreContextualHelpLinks()" *ngIf="isSearchFilterRequired"
                  title="View in Help Central" aria-label="Show all articles">... Show all articles</a>
              </div>
            </div>
          </div>


        </section>
        <section class="fxp_pageloader inlineloader" *ngIf="showHelpLoader" id="helpLoaderContainer">
          <span class="fxp_loaderText" id="help_loaderText" aria-live="assertive">Loading</span>
        </section>
      </div>
    </div>
  </div>

  <!--Normal Help-->
  <div *ngIf="!initialFlags.contextualHelpEnabled" class="btn-group pull-left helpflyoutmenu" ngbDropdown
    (clickOutside)="closeHelp()">
    <button id="help-open" type="button" class="icon-container withBorder" title="Help"
      (click)="toggleHelp(!isHelpOpen)">
      <i class="icon icon-help"></i>
    </button>
    <div class="fxp-help" aria-labelledby="help-open" *ngIf="isHelpOpen">
      <div class="help-content">
        <div class="help-heading" *ngIf="initialFlags.contextualHelpEnabled">
          <button id="help-back-button" type="button" class="icon-container" title="Back" aria-label="Back"
            [disabled]="navigationCollection.length <= 1 || currentNavigationIndex === 0"
            (click)="navigateToPreviousState()" (keydown)="setFocusToNextElement($event)">
            <i class="icon icon-prev"></i>
          </button>
          <button id="help-forward-button" type="button" class="icon-container" title="Forward" aria-label="Forward"
            [disabled]="navigationCollection.length === currentNavigationIndex + 1" (click)="navigateToNextState()">
            <i class="icon icon-next"></i>
          </button>
          <button id="help-home-button" type="button" class="icon-container" title="Home" aria-label="Home"
            [disabled]="navigationCollection[currentNavigationIndex] === 'home'" (click)="navigateToHome()">
            <i class="icon icon-home"></i>
          </button>
          <button role="link" id="open-help-central" type="button" title="Open help central"
            aria-label="Open help central" class="icon-container" (click)="navigateToHelpCentral()">
            <i class="icon icon-newtab"></i>
          </button>
          <button id="helpFlyoutPin" aria-label="pin" class="icon-container pull-right" (click)="onPinHelpFlyoutClick()"
            [title]="!isHelpFlyoutPinned ? 'Click to Pin' : 'Click to Unpin'">
            <i class="icon"
              [ngClass]="{'icon-pinLegacy': !isHelpFlyoutPinned, 'icon-unpinLegacy': isHelpFlyoutPinned}"></i>
          </button>
          <div class="help-row" *ngIf="isHelpOpen">
            <section><!-- Include help/search-control.html content here -->
              <div class="search-control contextual-help">
                <input type="text" id="HelpSearch_FormControl" class="input__search"
                  placeholder="Search all Help in ESXP" [(ngModel)]="searchValue"
                  (keydown)="onSearchKeyDown($event, searchValue)" (selectItem)="onSearchSuggestionSelect($event)"
                  role="combobox" />
                <button class="fxp-btn fxp-btn--secondary icon-container clear-btn contextual-help"
                  *ngIf="searchValue.length" (click)="clearSearch()" aria-label="Clear search box">
                  <i class="icon icon-close"></i>
                </button>
                <button class="fxp-btn fxp-btn--secondary icon-container pull-right"
                  (click)="searchHelpArticles(searchValue)" aria-label="Search">
                  <i class="icon icon-search"></i>
                </button>

                <ng-template #searchResultTemplate let-match="match">
                  <div [id]="match.model" class="result-item">
                    <span attr.aria-label="Search Result {{$index}} is {{match.model}}">{{match.model}}</span>
                  </div>
                </ng-template>
              </div>
            </section>
          </div>
        </div>
        <div class="helpHeading" *ngIf="!initialFlags.contextualHelpEnabled">Help</div>
        <section *ngIf="contextualHelp != null && navigationCollection[currentNavigationIndex] === 'home'">

          <div class="help contextual-help" *ngFor="let sectionKey of Object.keys(contextualHelp)">
            <ng-container *ngIf="initialFlags.contextualHelpEnabled && contextualHelp[sectionKey].length">
              <h3 class="font-base-alt help-row help-header" [innerHTML]="sectionKey"></h3>
              <div class="help-row font-caption"
                *ngFor="let item of contextualHelp[sectionKey] | slice: 0:(isContextualFilterRequired ? defaultHelpArticleLimit : contextualHelp[sectionKey].length)">
                <div class="help-title">
                  <a href (click)="navigateToArticle(item.id); logFxpHelpEvent(item, sectionKey, 'FlyoutView')"
                    [title]="item.title" [attr.aria-label]="item.title" [attr.fxp-set-focus]="item.pullFocus"
                    *ngIf="sectionKey === helpArticleTitles.HELP_RELATED_TO_THIS_PAGE" [innerHTML]="item.title"></a>
                  <a href (click)="logFxpHelpEvent(item, item.title, 'FlyoutView')" [title]="item.title"
                    [attr.aria-label]="item.title" [attr.fxp-help-events]="item"
                    *ngIf="sectionKey !== helpArticleTitles.HELP_RELATED_TO_THIS_PAGE" [innerHTML]="item.title"></a>
                  <a class="help-icon-container article-edit" [title]="'Edit'"
                    attr.aria-label="'Edit article ' + item.title" [href]="helpCentralUrl + '&ArticleTitle=' + item.title"
                    target="_blank">
                    <i class="icon icon-edit-row"></i>
                  </a>
                </div>
                <div *ngIf="item.description" class="help-description" [innerHTML]="item.description"></div>
              </div>
              <div class="help-row font-caption"
                *ngIf="isContextualFilterRequired && sectionKey === helpArticleTitles.HELP_RELATED_TO_THIS_PAGE">
                <div class="help-title">
                  <a href (click)="showMoreContextualHelpLinks()" aria-label="Show all related articles">... Show all
                    related articles</a>
                </div>
              </div>
            </ng-container>
          </div>


        </section>
        <section *ngIf="templateCheck('article')">
          <!-- Include help/help-article.html content here -->
          <div class="help contextual-help" *ngIf="article.title">
            <div class="font-base-alt help-row">
              <span id="articleTitle">{{ article.title }}</span>
              <a class="help-icon-container article-edit" title="Edit" attr.aria-label="'Edit article ' + article.title"
                fxp-set-focus="true" [href]="helpCentralUrl + '&ArticleTitle=' + article.title" target="_blank">
                <i class="icon icon-edit-row"></i>
              </a>
            </div>
            <div class="help-row article-content" [innerHTML]="article.articleContent" [fxpHelpArticle]="article.title">

            </div>
            <div class="help-row font-caption">
              <div class="help-title opacity-6">Was this article helpful?</div>
              <div class="help-description">
                <button class="fxp-btn fxp-btn--secondary font-caption-alt"
                  attr.aria-label="'Was this article helpful? Yes'" (click)="saveHelpFeedback(article.id, 'true')">
                  Yes
                </button>
                <button class="fxp-btn fxp-btn--secondary font-caption-alt" title="No"
                  attr.aria-label="'Was this article helpful? No'" (click)="saveHelpFeedback(article.id, 'false')">
                  No
                </button>
              </div>
            </div>
            <div class="help-row font-caption" *ngIf="hasScrollbar">
              <a href fxp-go-to-top parent="fxp-help" id="help-gotop-button" role="button"
                (keydown)="setFocusToNextElement($event)">Go to top</a>
            </div>
          </div>


        </section>
        <section *ngIf="templateCheck('search')">
          <!-- Include help/search-links.html content here -->
          <div class="help contextual-help">
            <div class="font-base-alt help-row">Articles</div>
            <div
              *ngFor="let item of searchedHelp | slice:0:(isSearchFilterRequired ? defaultHelpArticleLimit : searchedHelp.length); trackBy: item.id"
              class="help-row font-caption">
              <div class="help-title">
                <a href
                  (click)="navigateToArticle(item.id); logFxpHelpEvent(item, 'Help related to this page', 'FlyoutView')"
                  title="{{item.title}}" attr.aria-label="{{item.title}}" [innerHTML]="sanitizeHtml(item.title)"></a>
                <a class="help-icon-container article-edit" title="Edit" attr.aria-label="Edit article {{item.title}}"
                  [href]="helpCentralUrl + '&ArticleTitle=' + item.title" target="_blank">
                  <i class="icon icon-edit-row"></i>
                </a>
              </div>
              <div *ngIf="item.description" class="help-description" [innerHTML]="sanitizeHtml(item.description)"></div>
            </div>
            <div class="help-row font-caption">
              <div class="help-title">
                <a href (click)="showMoreContextualHelpLinks()" *ngIf="isSearchFilterRequired"
                  title="View in Help Central" aria-label="Show all articles">... Show all articles</a>                
              </div>
            </div>
          </div>
        </section>
        <section class="fxp_pageloader inlineloader" *ngIf="showHelpLoader" id="helpLoaderContainer">
          <span class="fxp_loaderText" id="help_loaderText" aria-live="assertive">Loading</span>
        </section>
      </div>
    </div>
  </div>
</div>